import { Subscription } from "./schemas";
import { humanizeDate } from "./utils/schema";

export function isSubscriptionActive(sub: Subscription): "yes" | "no" | "for-now" {
  switch (sub.status) {
    case "active":
      return sub.endsOn === undefined ? "yes" : "for-now";
    case "trialing":
    case "past_due":
    case "unpaid":
      return "yes";
    case "paused":
    case "incomplete":
    case "incomplete_expired":
    case "canceled":
      return "no";
  }
}

export function subscriptionRenewalLine(sub: Subscription): string {
  const expireDate = sub.endsOn ? `on ${humanizeDate(sub.endsOn)}` : "soon";
  switch (isSubscriptionActive(sub)) {
    case "yes":
      return "Your subscription is active";
    case "no":
      return "You do not have an active subscription.";
    case "for-now":
      return `Your subscription will expire ${expireDate}.`;
  }
}
