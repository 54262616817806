import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import React, { Suspense } from "react";
import { Effect, Layer } from "effect";



import { API } from "../api";
import { ApplicationSession, defaultApplicationSession, User } from "../auth";
import { Fetch } from "../utils/fetch";
import { Account } from "../schemas";

export interface RouterContext {
  session: ApplicationSession;
  setSession: (session: ApplicationSession) => void;
  // You can adjust this to be a different fetcher
  fetch: Layer.Layer<Fetch, never, never>;

  // TODO: Evaluate if this should be one object that gets instantiated when _auth is run maybe it
  // should all be under "ApplicationSession"
  user?: User | undefined;
  accounts?: Account[];
  selectedAccount?: Account | undefined;
}

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null // Render nothing in production
  : React.lazy(() =>
      // Lazy load in development
      import("@tanstack/router-devtools").then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    );

function Root() {
  return (
    <>
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  );
}

export const Route = createRootRouteWithContext<RouterContext>()({
  /**
   * NOTE(NZ): beforeLoad is called for every child route, be careful you don't put
   * resource intensive tasks here. Only things that absolutely have to exist before
   * the app can be served
   */
  beforeLoad: async ({ context, location }): Promise<{ session: ApplicationSession }> => {
    // Reuse the existing session if it isn't the default one or the route didn't request a reloaded
    // session
    if (!context.session.isDefault && !location.state.reloadSession) {
      return {
        session: context.session,
      };
    }

    const resp = await Effect.provide(API.getSession(), context.fetch).pipe(Effect.runPromiseExit);

    return resp._tag === "Failure"
      ? { session: defaultApplicationSession }
      : { session: resp.value };
  },
  component: Root,
});
