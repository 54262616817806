import {
  Link,
  Outlet,
  createFileRoute,
  redirect,
  useNavigate,
  useRouterState,
} from "@tanstack/react-router";
import { PropsWithChildren } from "react";
import { ExclamationCircleIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Effect } from "effect";

import { Card } from "../components/card";
import { API } from "../api";
import { User } from "../auth";
import { Account, AccountId } from "../schemas";
import { Alert } from "../components/alert";
import { AnyRoute } from "../utils/router";

type RouteNavigator = ReturnType<typeof Route.useNavigate>;

interface RouteContext {
  user: User;
  accounts: readonly Account[];
  selectedAccount: Account;
}

interface AuthProps {
  user: User;
  accounts: readonly Account[];
  selectedAccount: Account | undefined;
  navigate: RouteNavigator;
}

function ErrorComponent({ error }: { error: { message?: string } }) {
  const navigate = useNavigate();

  const errorProps: AuthProps = {
    user: {
      name: "",
      email: "",
    },
    accounts: [],
    selectedAccount: {
      name: "",
      id: "" as AccountId,
      role: "MEM",
    },
    navigate: navigate,
  };
  return (
    <>
      <Navigation {...errorProps} />
      <Page>
        <Card className="space-y-6">
          <h1 className="text-2xl font-bold">Uh Oh! There was a problem</h1>
          <div className="flex items-center">
            <ExclamationCircleIcon className="mr-2 size-5 text-red-600" />
            <p>{error.message}</p>
          </div>
        </Card>
      </Page>
    </>
  );
}

export const Route = createFileRoute("/_auth")({
  errorComponent: ErrorComponent,
  /**
   * Validates that a user is set and provides a convenience context variable `user: User`
   * for all downstream routes
   */
  beforeLoad: async ({ context, location }): Promise<RouteContext> => {
    const user = context.session.user;
    if (!context.session.isAuthenticated || !user) {
      throw redirect({
        to: "/signin",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          // @ts-expect-error https://tanstack.com/router/latest/docs/framework/react/guide/authenticated-routes#redirecting
          next: location.href,
        },
      });
    }

    // Don't reload everything is we already have them
    if (context.accounts !== undefined && context.selectedAccount !== undefined) {
      return {
        user: user,
        accounts: context.accounts,
        selectedAccount: context.selectedAccount,
      };
    }

    const resp = await Effect.provide(API.accounts(), context.fetch).pipe(Effect.runPromiseExit);

    // We have at least one account
    if (resp._tag === "Success" && resp.value.length > 0) {
      return {
        user: user,
        accounts: resp.value,
        selectedAccount: resp.value[0],
      };
    }

    // No accounts available, go to the account start page
    if (location.href == "/account-start") {
      return {
        user: user,
        accounts: [],
        selectedAccount: { name: "", id: "" as AccountId, role: "MEM" },
      };
    }

    throw redirect({
      to: "/account-start",
      state: {
        showMessage: {
          message: "You must create an account before continuing.",
          level: "warning",
        },
      },
    });
  },

  component: Component,
});

function AccountDropdown({ user, selectedAccount, navigate }: AuthProps) {
  const menuItemClasses = "w-full py-2 px-3 hover:bg-gray-100 cursor-pointer";
  const context = Route.useRouteContext();

  const signoutOnClick = () => {
    Effect.provide(API.signout(), context.fetch)
      .pipe(Effect.runPromiseExit)
      .then(() =>
        navigate({
          to: "/signin",
          search: {
            next: "/dashboard",
          },
          state: {
            reloadSession: true,
          },
        }),
      );
  };

  return (
    <Menu>
      <MenuButton>
        <div className="flex gap-4">
          {user.name} <UserCircleIcon className="size-6" />
        </div>
      </MenuButton>
      <MenuItems
        className={clsx(
          "mt-3 flex flex-col items-start rounded-md bg-white shadow-lg ring-1 ring-gray-100",
        )}
        anchor="bottom end"
      >
        {selectedAccount?.role == "OWN" && (
          <MenuItem>
            <Link to="/organization-profile">
              <p className={clsx(menuItemClasses, "flex flex-col")}>
                <span>Organization Settings</span>
                <span className="text-gray-400">({selectedAccount && selectedAccount.name})</span>
              </p>
            </Link>
          </MenuItem>
        )}

        <MenuItem>
          <p onClick={signoutOnClick} className={clsx(menuItemClasses)}>
            Sign out
          </p>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
}

function Navigation(props: AuthProps) {
  const navigation: {
    name: string;
    href: AnyRoute;
    current: boolean;
  }[] = [];

  return (
    <header className="bg-white shadow-sm">
      <div className="mx-auto max-w-7xl p-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center" role="navigation">
            <Link to="/dashboard">
              <img className="size-8" src="/logo.jpg" />
            </Link>
            <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  aria-current={item.current ? "page" : undefined}
                  className={clsx(
                    item.current
                      ? "border-indigo-500 text-gray-900"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium",
                  )}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          <AccountDropdown {...props} />
        </div>
      </div>
    </header>
  );
}

function Page(props: PropsWithChildren) {
  return (
    <main>
      <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">{props.children}</div>
    </main>
  );
}

function Component() {
  const navigate = Route.useNavigate();
  const context = Route.useRouteContext();
  const state = useRouterState();
  const alertMessage = state.location.state.showMessage;

  const props: AuthProps = {
    user: context.user,
    selectedAccount: context.selectedAccount,
    accounts: context.accounts,
    navigate: navigate,
  };

  return (
    <>
      <Navigation {...props} />
      {alertMessage && (
        <div className="mt-10 flex justify-center">
          <Alert level={alertMessage.level} headingText={alertMessage.heading}>
            {alertMessage.message}
          </Alert>
        </div>
      )}
      <Page>
        <Outlet />
      </Page>
    </>
  );
}
