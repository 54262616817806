import { Effect, Layer } from "effect";

import { ResourceId } from "../../schemas";
import { Fetch } from "../../utils/fetch";
import { API } from "../../api";
import { Route } from "../../routes/_auth/dashboard";

type Navigator = ReturnType<typeof Route.useNavigate>;

export interface DashboardProps {
  fetch: Layer.Layer<Fetch, never, never>;
  navigate: Navigator;
}

export async function deleteResource({
  resourceId,
  fetch,
}: {
  resourceId: ResourceId;
  fetch: Layer.Layer<Fetch, never, never>;
}): Promise<void> {
  await Effect.provide(API.deleteResource(resourceId), fetch).pipe(Effect.runPromiseExit);
}
