import { XMarkIcon, CheckIcon } from "@heroicons/react/24/solid";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Record } from "effect";
import { PropsWithChildren, useState } from "react";

import { Input } from "./input";

type HeadingElements = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

type SharedProps = {
  size: "sm" | "lg" | "3xl";
  as?: HeadingElements;
  className?: string;
};

export type HeadingProps = PropsWithChildren & SharedProps;

const sizeClasses: Record<HeadingProps["size"], string> = {
  sm: "text-sm",
  lg: "text-xl lg:text-xl",
  "3xl": "text-5xl lg:text-4xl sm:text-6xl",
};

const componentForSize: Record<HeadingProps["size"], HeadingElements> = {
  sm: "h5",
  lg: "h2",
  "3xl": "h1",
};

export function Heading({ size, as, children, className }: HeadingProps) {
  const Component = as ? as : componentForSize[size];
  return (
    <Component
      className={clsx("font-bold tracking-tight text-gray-900", sizeClasses[size], className)}
    >
      {children}
    </Component>
  );
}

export type EditableHeadingProps = {
  id: string;
  onChange: (value: string) => void;
  text: string;
} & SharedProps;

export function EditableHeading({ text, ...props }: EditableHeadingProps) {
  const [original] = useState(text);
  const [editing, setEditing] = useState(false);
  const [display, setDisplay] = useState(text);

  const finish = ({ change }: { change: boolean }) => {
    if (change) props.onChange(display);
    if (!change) setDisplay(original);
    setEditing((v) => !v);
  };

  const notEditable = (
    <div
      className="group flex cursor-pointer items-center gap-6"
      onClick={() => {
        setEditing((v) => !v);
      }}
    >
      <Heading {...props}>{display}</Heading>
      <PencilSquareIcon className="size-5 text-gray-400 group-hover:text-gray-600" />
    </div>
  );

  const editable = (
    <div
      id={`parent-${props.id}`}
      className={clsx("flex items-center gap-3", sizeClasses[props.size])}
    >
      <Input
        id={`input-${props.id}`}
        value={display}
        className={clsx(sizeClasses[props.size])}
        onChange={(e) => setDisplay(e.target.value)}
        shouldFocus={true}
        onKeyUp={(e) => {
          if (e.key === "Enter") finish({ change: true });
          if (e.key === "Escape") finish({ change: false });
        }}
      />
      <div className="flex gap-2">
        <div onClick={() => finish({ change: true })}>
          <CheckIcon className="mt-2 size-6 cursor-pointer text-green-700" title="Submit" />
        </div>
        <div onClick={() => finish({ change: false })}>
          <XMarkIcon className="mt-2 size-6 cursor-pointer text-red-700" title="Cancel" />
        </div>
      </div>
    </div>
  );

  return editing ? editable : notEditable;
}
