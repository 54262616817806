import { Brand, Schema as S } from "effect";

export const ErrorDetailSchema = S.Struct({
  message: S.String,
  code: S.String,
  param: S.NullOr(S.String),
});

export type ErrorDetail = typeof ErrorDetailSchema.Type;
export const ResponseErrorMessagesSchema = S.Array(ErrorDetailSchema);
export type ResponseErrorMessages = typeof ResponseErrorMessagesSchema.Type;

export const errorResponseSchema = S.Struct({
  data: S.optional(S.Null),
  errors: ResponseErrorMessagesSchema,
  meta: S.optional(S.Struct({})),
});

export const alternativeResponseSchema = <A, I, R>({ data }: { data: S.Schema<A, I, R> }) =>
  S.Struct({
    data: data,
    errors: S.Null,
    meta: S.Struct({
      pagination: S.optional(PaginationSchema),
    }),
  });

const PaginationSchema = S.Struct({
  count: S.Number,
  // TODO: These should have validation on them that they are URL's
  next: S.String,
  previous: S.String,
});

export type ResourceId = string & Brand.Brand<"ResourceId">;
export const ResourceId = Brand.nominal<ResourceId>();
export const ResourceIdSchema = S.String.pipe(S.fromBrand(ResourceId));
const ResourceSchema = S.Struct({
  id: ResourceIdSchema,
  name: S.String,
  url: S.NullOr(S.String),
  filename: S.NullOr(S.String),
});
export type Resource = typeof ResourceSchema.Type;

export const resourceResponseDecoder = S.decode(
  alternativeResponseSchema({ data: ResourceSchema }),
);

const DashboardSchema = S.Struct({
  resources: S.Array(ResourceSchema),
});

export type DashboardData = typeof DashboardSchema.Type;

const DashboardResponseSchema = alternativeResponseSchema({
  data: DashboardSchema,
});

export const dashboardResponseDecoder = S.decode(DashboardResponseSchema);

/**
 * ACCOUNT
 */
export type AccountProviderId = string & Brand.Brand<"AccountProviderId">;
export const AccountProviderId = Brand.nominal<AccountProviderId>();

export type AccountId = string & Brand.Brand<"AccountId">;
export const AccountId = Brand.nominal<AccountId>();
export const AccountIdSchema = S.String.pipe(S.fromBrand(AccountId));

const AccountSchema = S.Struct({
  id: AccountIdSchema,
  name: S.String,
  role: S.Literal("OWN", "MEM"),
});

export type Account = typeof AccountSchema.Type;

export const accountListResponseDecoder = S.decode(
  alternativeResponseSchema({ data: S.Array(AccountSchema) }),
);
export const accountCreateResponseDecoder = S.decode(
  alternativeResponseSchema({ data: AccountSchema }),
);

/**
 * PAYMENT INTENT
 */
const PaymentIntentSecretSchema = S.String.pipe(S.brand("PaymentIntentSecret"));
const PaymentIntentSchema = S.Struct({
  clientSecret: PaymentIntentSecretSchema,
});

export type PaymentIntentSecret = typeof PaymentIntentSecretSchema.Type;
export type PaymentIntent = typeof PaymentIntentSchema.Type;

export const paymentIntentDecoder = S.decode(
  alternativeResponseSchema({ data: PaymentIntentSchema }),
);

/**
 * Subscription
 */
const SubscriptionSchema = S.Struct({
  status: S.Literal(
    "active",
    "canceled",
    "incomplete",
    "incomplete_expired",
    "past_due",
    "paused",
    "trialing",
    "unpaid",
  ),
  endsOn: S.NullOr(
    S.transform(S.Number, S.DateTimeUtcFromNumber, {
      // API provides this in seconds, this expects milliseconds
      decode: (a) => a * 1000,
      encode: (a) => a / 1000,
    }),
  ),
});
export type Subscription = typeof SubscriptionSchema.Type;
export const subscriptionDecoder = S.decode(
  alternativeResponseSchema({ data: SubscriptionSchema }),
);
