import { createRouter } from "@tanstack/react-router";

import { routeTree } from "./routeTree.gen";
import { defaultApplicationSession } from "./auth";
import { FetchLive } from "./utils/fetch";

export const router = createRouter({
  routeTree,
  context: {
    session: defaultApplicationSession,
    setSession: undefined!,
    fetch: FetchLive,
    user: undefined,
    accounts: [],
    selectedAccount: undefined!,
  },
});

export type RouterType = typeof router;
