import "./index.css";

import { createRoot } from "react-dom/client";

import type { RouterType } from "./router";
import { Application } from "./app";

const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = createRoot(rootElement);

  root.render(<Application />);
}

declare module "@tanstack/react-router" {
  interface Register {
    router: RouterType;
  }

  interface HistoryState {
    reloadSession?: boolean;
    showMessage?: {
      message: string;
      level: "error" | "warning" | "info" | "success";
      heading?: string;
    };
  }
}
