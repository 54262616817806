import { Effect, Layer } from "effect";

import { API } from "../../api";
import { Fetch } from "../../utils/fetch";
import { Route } from "../../routes/_auth/account-start";

type Navigator = ReturnType<typeof Route.useNavigate>;
type ErrorSetter = React.Dispatch<React.SetStateAction<string>>;

export interface AccountStartProps {
  globalError: [string, ErrorSetter];
  navigate: Navigator;
  fetch: Layer.Layer<Fetch, never, never>;
}

export const submitFormToApi = async (
  name: string,
  navigate: Navigator,
  setError: ErrorSetter,
  fetch: Layer.Layer<Fetch, never, never>,
) => {
  const resp = await Effect.provide(API.createAccount(name), fetch).pipe(Effect.runPromiseExit);

  if (resp._tag === "Success") {
    navigate({ to: "/subscribe" });
  } else {
    setError(
      resp.cause._tag === "Fail"
        ? resp.cause.error.displayMessage
        : "Unable to start an account at this time",
    );
  }
};

export const defaultFormValues = {
  name: "",
};
