import { createFileRoute, Link } from "@tanstack/react-router";
import { Effect } from "effect";
import { TrashIcon } from "@heroicons/react/24/solid";

import { API } from "../../api";
import { Card } from "../../components/card";
import { Button } from "../../components/button";
import { Resource } from "../../schemas";
import { DashboardProps, deleteResource } from "../../pages/dashboard/lib";

export const Route = createFileRoute("/_auth/dashboard")({
  component: Component,

  /**
   * Note the use of `runPromise` as opposed to `runPromiseExit`. This is so that any errors
   * when running the promise will be bubbled up to to components error handler and an error page
   * is shown. We map all the errors to a simple and friendly error message.
   */
  loader: async ({ context }) =>
    await Effect.provide(API.dashboard(), context.fetch).pipe(Effect.runPromiseExit),
});

function ResourceLine({ resource, props }: { resource: Resource; props: DashboardProps }) {
  const resourceDeleteHandler = () => {
    deleteResource({ resourceId: resource.id, fetch: props.fetch });

    // Reload the page
    props.navigate({ to: "." });
  };

  return (
    <div className="flex w-full cursor-pointer items-center justify-between gap-4 border-b-2 py-3">
      <Link to="/resource/$id" params={{ id: resource.id }}>
        <div className="">{resource.name}</div>
      </Link>
      <div className="" onClick={resourceDeleteHandler}>
        <TrashIcon className="size-6 text-red-700 hover:text-red-400" />
      </div>
    </div>
  );
}

function Component() {
  const data = Route.useLoaderData();
  const ctx = Route.useRouteContext();

  const resources = data._tag === "Success" ? data.value.resources : [];
  const props: DashboardProps = {
    fetch: ctx.fetch,
    navigate: Route.useNavigate(),
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex justify-end">
        <div className="w-fit">
          <Link to="/resource">
            <Button color="green">Add Resource</Button>
          </Link>
        </div>
      </div>
      <Card>
        {resources.length > 0
          && resources.map((item: Resource) => (
            <ResourceLine key={item.id} resource={item} props={props} />
          ))}
        {resources.length == 0 && "No resources, try adding one."}
      </Card>
    </div>
  );
}
