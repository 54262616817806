/* eslint-disable react/no-children-prop */
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useState } from "react";
import { FormApi, useForm } from "@tanstack/react-form";
import { Effect } from "effect";

import { Card } from "../../components/card";
import { Input } from "../../components/input";
import { Button } from "../../components/button";
import { API } from "../../api";
import { APIError } from "../../utils/fetch";
import { ErrorDetail } from "../../schemas";

export const Route = createFileRoute("/_public/signup")({
  component: Component,
  beforeLoad: ({ context }) => {
    if (context.session.isAuthenticated) {
      return redirect({ to: "/dashboard" });
    }
  },
});

function Header() {
  return (
    <div>
      <h1 className="text-center text-xl leading-9">Digital Collections</h1>
      <h2 className="mt-3 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Sign up
      </h2>
    </div>
  );
}

const defaultValues = {
  email: "",
  password: "",
};

type FormType = typeof defaultValues;

function errorToMessages(
  formApi: FormApi<FormType>,
  globalErrorHandler: React.Dispatch<React.SetStateAction<string[]>>,
  error: APIError,
) {
  error.payload.forEach(({ param, message }: ErrorDetail) => {
    switch (param) {
      case "email":
      case "password":
        formApi.setFieldMeta(param, (prev) => ({
          ...prev,
          errorMap: { onBlur: message },
        }));
        break;
      default:
        globalErrorHandler((curr) => {
          curr.push("The form submission failed. Try again?");
          return curr;
        });
        break;
    }
  });
}

function SigninForm() {
  const [globalError, setGlobalError] = useState<string[]>([]);
  const { setSession, fetch } = Route.useRouteContext();
  const navigate = Route.useNavigate();

  const form = useForm({
    defaultValues: defaultValues,
    onSubmit: async ({ value, formApi }) => {
      setGlobalError([]);
      const resp = await Effect.provide(API.signup(value.email, value.password), fetch).pipe(
        Effect.runPromiseExit,
      );

      if (resp._tag === "Success") {
        setSession(resp.value);
        navigate({ to: "/dashboard" });
      } else if (resp.cause._tag === "Fail" && resp.cause.error.original?._tag == "APIError") {
        errorToMessages(formApi, setGlobalError, resp.cause.error.original);
      } else if (resp.cause._tag === "Fail") {
        setGlobalError([resp.cause.error.displayMessage]);
      } else {
        setGlobalError(["An error occured."]);
      }
    },
  });

  return (
    <form
      className="space-y-6"
      onSubmit={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await form.handleSubmit();
      }}
    >
      <div data-testid="fields" className="space-y-6">
        {globalError && globalError.join(" ")}
        <form.Field
          name="email"
          validators={{
            onBlur: ({ value }) => {
              if (!value.includes("@")) return "Not a valid email address";
            },
          }}
          children={(field) => (
            <Input
              id="email-address"
              name="email-address"
              type="email"
              label="Email Address"
              placeholder="Email Address"
              autoComplete="email"
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              error={field.state.meta.errors.join(" ")}
              required
            />
          )}
        />
        <form.Field
          name="password"
          validators={{
            onBlur: (value) => {
              if (!value.value) return "You must enter a password";
            },
          }}
          children={(field) => (
            <Input
              id="password"
              name="password"
              type="password"
              label="Password"
              placeholder="Password"
              autoComplete="new-password"
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              required
              error={field.state.meta.errors.join(" ")}
            />
          )}
        />
      </div>
      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
        children={([canSubmit, isSubmitting]) => (
          <Button
            disabled={!canSubmit}
            title={canSubmit ? "Sign Up" : "Correct form errors to sign up"}
          >
            {isSubmitting ? "..." : "Sign Up"}
          </Button>
        )}
      />
    </form>
  );
}

function Component() {
  return (
    <Card className="space-y-6">
      <Header />
      <SigninForm />
    </Card>
  );
}
