import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

import { Card } from "../../components/card";
import { AccountStartForm, AccountStartHeader } from "../../pages/account-start/components";

export const Route = createFileRoute("/_auth/account-start")({
  component: () => <Component />,
});

function Component() {
  const globalError = useState<string>("");
  const navigate = Route.useNavigate();
  const { fetch } = Route.useRouteContext();

  const props = {
    globalError: globalError,
    navigate: navigate,
    fetch: fetch,
  };

  return (
    <div className="flex justify-center">
      <div className="max-w-2xl">
        <Card className="flex flex-col gap-12">
          <AccountStartHeader />
          <AccountStartForm {...props} />
        </Card>
      </div>
    </div>
  );
}
