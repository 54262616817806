import { Schema } from "effect";

export type User = {
  readonly name: string;
  readonly email: string;
};

export type ApplicationSession = {
  isDefault: boolean;
  isAuthenticated: boolean;
  user?: User;
};

export const defaultApplicationSession: ApplicationSession = {
  isDefault: true,
  isAuthenticated: false,
};

const buildResponseSchema = <A, I, R, B, J, T>({
  data,
  meta,
}: {
  data: Schema.Schema<A, I, R>;
  meta: Schema.Schema<B, J, T>;
}) =>
  Schema.Struct({
    status: Schema.Number,
    data: data,
    meta: meta,
  });

const AuthenticatedSessionResponseSchema = buildResponseSchema({
  data: Schema.Struct({
    user: Schema.Struct({
      id: Schema.String,
      display: Schema.String,
      has_usable_password: Schema.Boolean,
      email: Schema.String,
    }),
    methods: Schema.Array(
      Schema.Struct({
        method: Schema.Literal("password", "socialaccount"),
        at: Schema.Number,
        provider: Schema.optional(Schema.String),
        email: Schema.optional(Schema.String),
        uid: Schema.optional(Schema.String),
      }),
    ),
  }),
  meta: Schema.Struct({
    is_authenticated: Schema.Boolean,
  }),
});

export type AuthenticatedSessionResponseSchemaType = typeof AuthenticatedSessionResponseSchema.Type;

export const authenticatedSessionDecoder = Schema.decode(AuthenticatedSessionResponseSchema);

export function toApplicationSession(
  payload: AuthenticatedSessionResponseSchemaType,
): ApplicationSession {
  return {
    user: {
      name: payload.data.user.display,
      email: payload.data.user.email,
    },
    isAuthenticated: payload.meta.is_authenticated,
    isDefault: false,
  };
}
