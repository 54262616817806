import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

import { Card } from "../../components/card";
import { Heading } from "../../components/heading";
import { AddResourceProps } from "../../pages/resource/lib";
import { AddResourceForm } from "../../pages/resource/components";

export const Route = createFileRoute("/_auth/resource/")({
  component: () => <Component />,
});

function Component() {
  const ctx = Route.useRouteContext();
  const globalError = useState<string>("");
  const props: AddResourceProps = {
    fetch: ctx.fetch,
    globalError: globalError,
    navigate: Route.useNavigate(),
  };

  return (
    <Card>
      <div className="flex justify-center py-12">
        <div className="flex max-w-xl flex-col gap-y-4">
          <Heading size="3xl" className="mb-6 ">
            New Resource
          </Heading>
          <AddResourceForm {...props} />
        </div>
      </div>
    </Card>
  );
}
