import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState, useTransition } from "react";
import { Effect } from "effect";

import { SubscribePage } from "../../pages/subscribe/components";
import { PlanView, SubscribeTab, SubscriptionProps } from "../../pages/subscribe/lib";
import { Plan } from "../../constants";
import { API } from "../../api";

export const Route = createFileRoute("/_auth/subscribe")({
  component: () => <Component />,
});

function Component() {
  const [clientSecret, setClientSecret] = useState<string>("");

  // TODO: This should likely be a useTransition
  const [currentTab, selectTab] = useState<SubscribeTab>("pricing");
  const [tabIsPending, tabStartTransition] = useTransition();
  const [selectedPlan, setSelectedPrice] = useState<Plan>("none");
  const [planView, setPlanView] = useState<PlanView>("show");
  const context = Route.useRouteContext();
  const navigate = Route.useNavigate();

  useEffect(() => {
    if (selectedPlan === "none" || clientSecret) return;

    Effect.provide(API.beginSubscribe(context.selectedAccount.id, selectedPlan), context.fetch)
      .pipe(Effect.runPromiseExit)
      .then((resp) => {
        switch (resp._tag) {
          case "Success":
            setClientSecret(resp.value.clientSecret);
            break;
          case "Failure":
            if (resp.cause._tag === "Fail") {
              navigate({
                to: "/dashboard",
                state: {
                  showMessage: {
                    message: resp.cause.error.displayMessage,
                    level: "error",
                  },
                },
              });
            }
            setSelectedPrice("none");
            setPlanView("show");
            break;
        }
      });
  }, [
    clientSecret,
    planView,
    selectedPlan,
    context.fetch,
    context.selectedAccount,
    navigate,
    setClientSecret,
  ]);

  const props: SubscriptionProps = {
    tab: currentTab,
    selectTab: (tab: SubscribeTab) => tabStartTransition(() => selectTab(tab)),
    tabIsPending: tabIsPending,
    clientSecret: clientSecret,
    planView: planView,
    selectedPlan: selectedPlan,
    selectPlan: (plan: Plan) => {
      setSelectedPrice(plan);
      tabStartTransition(() => selectTab("payment"));
    },
    selectedAccount: context.selectedAccount,
  };

  return <SubscribePage {...props} />;
}
