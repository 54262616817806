import { Effect } from "effect";

import { ApplicationSession, authenticatedSessionDecoder, toApplicationSession } from "./auth";
import {
  AUTH_BASE_URL,
  BACKEND_URL,
  DisplayableError,
  Fetch,
  HttpMethod,
  extractData,
  getPayload,
  requireOk,
  standardErrorMessages,
} from "./utils/fetch";
import {
  Account,
  accountCreateResponseDecoder,
  AccountId,
  accountListResponseDecoder,
  DashboardData,
  dashboardResponseDecoder,
  PaymentIntent,
  paymentIntentDecoder,
  Resource,
  ResourceId,
  resourceResponseDecoder,
  Subscription,
  subscriptionDecoder,
} from "./schemas";
import { Plan } from "./constants";

export const API = {
  /**
   * Return the session for the user. If the user doesn't have a session a default session is
   * returned with a blank user.
   */
  getSession: (): Effect.Effect<ApplicationSession, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({ method: HttpMethod.GET, url: `${AUTH_BASE_URL}/auth/session` }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(authenticatedSessionDecoder),
      Effect.map(toApplicationSession),
      Effect.mapError(standardErrorMessages),
    ),

  signin: (
    email: string,
    password: string,
  ): Effect.Effect<ApplicationSession, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({
          method: HttpMethod.POST,
          url: `${AUTH_BASE_URL}/auth/login`,
          data: {
            email: email,
            password: password,
          },
        }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(authenticatedSessionDecoder),
      Effect.map(toApplicationSession),
      Effect.mapError(standardErrorMessages),
    ),

  signup: (
    email: string,
    password: string,
  ): Effect.Effect<ApplicationSession, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({
          method: HttpMethod.POST,
          url: `${AUTH_BASE_URL}/auth/signup`,
          data: {
            email: email,
            password: password,
          },
        }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(authenticatedSessionDecoder),
      Effect.map(toApplicationSession),
      Effect.mapError(standardErrorMessages),
    ),

  signout: (): Effect.Effect<Response, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({ method: HttpMethod.DELETE, url: `${AUTH_BASE_URL}/auth/session` }),
      ),
      Effect.mapError(standardErrorMessages),
    ),

  accounts: (): Effect.Effect<readonly Account[], DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) => f.fetch({ method: HttpMethod.GET, url: `${BACKEND_URL}/api/account` })),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(accountListResponseDecoder),
      Effect.map(extractData),
      Effect.mapError(standardErrorMessages),
    ),

  createAccount: (name: string): Effect.Effect<Account, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({
          method: HttpMethod.POST,
          url: `${BACKEND_URL}/api/account`,
          data: {
            name,
          },
        }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(accountCreateResponseDecoder),
      Effect.map(extractData),
      Effect.mapError(standardErrorMessages),
    ),

  beginSubscribe: (
    accountId: string,
    plan: Plan,
  ): Effect.Effect<PaymentIntent, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({
          method: HttpMethod.POST,
          url: `${BACKEND_URL}/api/payment-intent`,
          data: {
            accountId: accountId,
            plan: plan,
          },
        }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(paymentIntentDecoder),
      Effect.map(extractData),
      Effect.mapError(standardErrorMessages),
    ),

  cancelSubscribe: (accountId: AccountId): Effect.Effect<Subscription, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({
          method: HttpMethod.DELETE,
          url: `${BACKEND_URL}/api/account/${accountId}/subscription`,
          data: {
            accountId: accountId,
          },
        }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(subscriptionDecoder),
      Effect.map(extractData),
      Effect.mapError(standardErrorMessages),
    ),

  subscription: (accountId: string): Effect.Effect<Subscription, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({
          method: HttpMethod.GET,
          url: `${BACKEND_URL}/api/account/${accountId}/subscription`,
        }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(subscriptionDecoder),
      Effect.map(extractData),
      Effect.mapError(standardErrorMessages),
    ),

  dashboard: (): Effect.Effect<DashboardData, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({ method: HttpMethod.GET, url: `${BACKEND_URL}/api/dashboard` }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(dashboardResponseDecoder),
      Effect.map(extractData),
      Effect.mapError(standardErrorMessages),
    ),

  createResource: (name: string): Effect.Effect<Resource, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({
          url: `${BACKEND_URL}/api/resource`,
          method: HttpMethod.POST,
          data: { name },
        }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(resourceResponseDecoder),
      Effect.map(extractData),
      Effect.mapError(standardErrorMessages),
    ),

  getResource: (resourceId: ResourceId): Effect.Effect<Resource, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({
          url: `${BACKEND_URL}/api/resource/${resourceId}`,
          method: HttpMethod.GET,
        }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(resourceResponseDecoder),
      Effect.map(extractData),
      Effect.mapError(standardErrorMessages),
    ),

  deleteResource: (resourceId: ResourceId): Effect.Effect<boolean, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({
          url: `${BACKEND_URL}/api/resource/${resourceId}`,
          method: HttpMethod.DELETE,
        }),
      ),
      Effect.map((resp) => resp.status == 404 || (resp.status >= 200 && resp.status < 300)),
      Effect.mapError(standardErrorMessages),
    ),

  updateResourceFile: (
    resc: Resource,
    data: FormData,
  ): Effect.Effect<Resource, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({
          method: HttpMethod.POST,
          url: `${BACKEND_URL}/api/resource/${resc.id}/file`,
          files: data,
        }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(resourceResponseDecoder),
      Effect.map(extractData),
      Effect.mapError(standardErrorMessages),
    ),

  updateResource: (resc: Resource): Effect.Effect<Resource, DisplayableError, Fetch> =>
    Fetch.pipe(
      Effect.flatMap((f) =>
        f.fetch({
          method: HttpMethod.PATCH,
          url: `${BACKEND_URL}/api/resource/${resc.id}`,
          data: {
            name: resc.name,
          },
        }),
      ),
      Effect.flatMap(requireOk),
      Effect.flatMap(getPayload),
      Effect.flatMap(resourceResponseDecoder),
      Effect.map(extractData),
      Effect.mapError(standardErrorMessages),
    ),
};
