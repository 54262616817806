import { useForm } from "@tanstack/react-form";

import { Heading } from "../../components/heading";
import { Input } from "../../components/input";
import { Button } from "../../components/button";
import { Alert } from "../../components/alert";
import { supportEmail } from "../../constants";

import { AccountStartProps, defaultFormValues, submitFormToApi } from "./lib";

export function AccountStartForm(props: AccountStartProps) {
  const [globalError, setError] = props.globalError;
  const form = useForm({
    onSubmit: async ({ value }) =>
      submitFormToApi(value.name, props.navigate, setError, props.fetch),
    defaultValues: defaultFormValues,
  });

  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={async (event) => {
        event.preventDefault();
        event.stopPropagation();
        await form.handleSubmit();
      }}
    >
      {globalError && (
        <Alert level="warning">
          {globalError} Contact{" "}
          <a className="font-bold underline" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>{" "}
          for assistance.
        </Alert>
      )}
      <form.Field
        name="name"
        validators={{
          onBlur: (value) => (!value.value ? "You must enter an institution name" : null),
        }}
      >
        {(field) => (
          <Input
            id="name"
            label="Institution Name"
            placeholder="Community College of Detroit"
            type="text"
            required
            error={field.state.meta.errors.join("\n")}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
          />
        )}
      </form.Field>

      <form.Subscribe
        selector={(state) => ({ canSubmit: state.canSubmit, submitting: state.isSubmitting })}
      >
        {({ canSubmit, submitting }) => (
          <Button disabled={!canSubmit}>{submitting ? "..." : "Submit"}</Button>
        )}
      </form.Subscribe>
    </form>
  );
}

export function AccountStartHeader() {
  return (
    <div className="flex flex-col gap-y-4">
      <Heading size="lg">Welcome to DigiCol</Heading>
      <p className="text-lg">
        DigiCol is a way to share digital collections with your students, employees, or reasearchers
        that links directly into your existing library framework. To get started we need a little
        bit of information.
      </p>
    </div>
  );
}
