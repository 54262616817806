import { Effect, Layer } from "effect";

import { API } from "../../api";
import { Fetch } from "../../utils/fetch";
import { Route } from "../../routes/_auth/resource.index";
import { Resource } from "../../schemas";

type Navigator = ReturnType<typeof Route.useNavigate>;

type ErrorSetter = React.Dispatch<React.SetStateAction<string>>;
export interface AddResourceProps {
  fetch: Layer.Layer<Fetch, never, never>;
  navigate: Navigator;
  globalError: [string, ErrorSetter];
}

export const defaultValues = {
  name: "",
};

export async function createResource(
  values: typeof defaultValues,
  navigate: Navigator,
  fetch: Layer.Layer<Fetch, never, never>,
  setError: ErrorSetter,
) {
  await Effect.provide(
    API.createResource(values.name).pipe(
      Effect.match({
        onFailure: (err) => {
          setError(err.displayMessage);
        },
        onSuccess: (val) => {
          navigate({ to: "/resource/$id", params: { id: val.id } });
        },
      }),
    ),
    fetch,
  ).pipe(Effect.runPromiseExit);
}

export async function updateResourceName({
  resource,
  fetch,
  setError,
}: {
  resource: Resource;
  fetch: Layer.Layer<Fetch, never, never>;
  setError: ErrorSetter;
}) {
  return await Effect.provide(
    API.updateResource(resource).pipe(
      Effect.match({
        onFailure: (err) => setError(err.displayMessage),
        onSuccess: () => Effect.as(true),
      }),
    ),
    fetch,
  ).pipe(Effect.runPromiseExit);
}

export async function updateResourceFile(
  resource: Resource,
  file: File,
  fetch: Layer.Layer<Fetch, never, never>,
  setError: ErrorSetter,
) {
  const fd = new FormData();
  fd.append("file", file);
  const resp = await Effect.provide(
    API.updateResourceFile(resource, fd).pipe(
      Effect.match({
        onFailure: (err) => {
          setError(err.displayMessage);
        },
        onSuccess: () => {},
      }),
    ),
    fetch,
  ).pipe(Effect.runPromiseExit);

  return resp._tag === "Success";
}
