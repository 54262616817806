import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { PropsWithChildren } from "react";

const colors = {
  error: {
    background: "bg-red-50",
    icon: "text-red-400",
    heading: "text-red-800",
    text: "text-red-700",
  },
  warning: {
    background: "bg-yellow-50",
    icon: "text-yellow-400",
    heading: "text-yellow-800",
    text: "text-yellow-700",
  },
  info: {
    background: "bg-blue-50",
    icon: "text-blue-400",
    heading: "text-blue-800",
    text: "text-blue-700",
  },
  success: {
    background: "bg-green-50",
    icon: "text-green-400",
    heading: "text-green-800",
    text: "text-green-700",
  },
};

const defaultHeadingText = {
  error: "Error",
  warning: "Warning",
  info: "Notice",
  success: "Success",
};

interface AlertProps {
  headingText?: string | undefined;
  level: "error" | "warning" | "info" | "success";
}

/**
 * TODO: Make this dismissable so that they don't persist forever
 */
export function Alert({ children, level, headingText }: PropsWithChildren & AlertProps) {
  const color = colors[level];

  return (
    <div className={clsx("rounded-md p-4", color.background)}>
      <div className="flex">
        <div className="shrink-0">
          <ExclamationTriangleIcon aria-hidden="true" className={clsx("size-5", color.icon)} />
        </div>
        <div className="ml-3">
          <h3 className={clsx("text-sm font-medium", color.heading)}>
            {headingText || defaultHeadingText[level]}
          </h3>
          <div className={clsx("mt-2 text-sm", color.text)}>
            <p>{children}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
