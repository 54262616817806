import { CheckIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormEventHandler } from "react";

import { Button } from "../../components/button";
import { Card } from "../../components/card";
import { Heading } from "../../components/heading";
import { stripeLoader } from "../../constants";

import { SubscriptionProps } from "./lib";

function PaymentDetailsForm(props: SubscriptionProps) {
  const stripe = useStripe();
  const elements = useElements();
  console.log(props.clientSecret);

  const handleSubmit: FormEventHandler<HTMLElement> = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/dashboard?paymentComplete=1`,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        options={{
          layout: {
            type: "accordion",
            defaultCollapsed: false,
            spacedAccordionItems: true,
          },
        }}
      />
      <p className="mt-6 flex items-baseline justify-center gap-x-2">
        <span className="text-5xl font-semibold tracking-tight text-gray-900">$549</span>
        <span className="text-sm/6 font-semibold tracking-wide text-gray-600">USD</span>
      </p>
      <div className="mt-10">
        <Button type="submit">Complete Purchase</Button>
      </div>
      <p className="mt-6 text-xs/5 text-gray-600"></p>
    </form>
  );
}

function MonthlyStandard(props: SubscriptionProps) {
  const features = [
    "Unlimited resources",
    "Unlimited students",
    "Unlimited downloads",
    "1 Institution",
  ];
  return (
    <div>
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col items-center justify-center sm:text-center">
          <Heading className="text-pretty font-semibold tracking-tight sm:text-balance" size="3xl">
            Simple Plans
          </Heading>
          <p className="mx-auto mt-6 max-w-2xl text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
            Our simple pricing system prevents you from having any surprises.
          </p>
        </div>
      </div>
      <div
        className={clsx(
          "mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none",
        )}
      >
        <div className="p-8 sm:p-10 lg:flex-auto">
          <h3 className="text-3xl font-semibold tracking-tight text-gray-900">All Access</h3>
          <p className="mt-6 text-base/7 text-gray-600">
            An institutions publications in one spot accessible by your students and researches any
            time.
          </p>
          <div className="mt-10 flex items-center gap-x-4">
            <h4 className="flex-none text-sm/6 font-semibold text-indigo-600">What’s included</h4>
            <div className="h-px flex-auto bg-gray-100" />
          </div>
          <ul
            role="list"
            className="mt-8 grid grid-cols-1 gap-4 text-sm/6 text-gray-600 sm:grid-cols-2 sm:gap-6"
          >
            {features.map((feature) => (
              <li key={feature} className="flex gap-x-3">
                <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                {feature}
              </li>
            ))}
          </ul>
        </div>
        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:shrink-0">
          <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
            <div className={clsx("mx-auto max-w-xs px-8")}>
              <p className="text-base font-semibold text-gray-600">Recurring Monthly Charge</p>
              <p className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-semibold tracking-tight text-gray-900">$549</span>
                <span className="text-sm/6 font-semibold tracking-wide text-gray-600">USD</span>
              </p>
              <div className="mt-10">
                <Button onClick={() => props.selectPlan("standard_monthly")}>Get access</Button>
              </div>
              <p className="mt-6 text-xs/5 text-gray-600"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SubscribePage(props: SubscriptionProps) {
  console.log(props.tab, props.clientSecret);

  const showPricing = props.tab === "pricing" || props.clientSecret === "";

  return (
    <div className="flex justify-center">
      <div className="max-w-screen-lg sm:w-screen">
        <Card className="flex flex-col gap-1">
          {showPricing && <MonthlyStandard {...props} />}
          {!showPricing && (
            <Elements stripe={stripeLoader} options={{ clientSecret: props.clientSecret }}>
              <PaymentDetailsForm {...props} />
            </Elements>
          )}
        </Card>
      </div>
    </div>
  );
}
