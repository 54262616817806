import { useForm } from "@tanstack/react-form";

import { supportEmail } from "../../constants";
import { Alert } from "../../components/alert";
import { Input } from "../../components/input";
import { Button } from "../../components/button";

import { createResource, defaultValues, AddResourceProps } from "./lib";

export function AddResourceForm(props: AddResourceProps) {
  const [globalError, setError] = props.globalError;
  const form = useForm({
    defaultValues,
    onSubmit: ({ value }) => createResource(value, props.navigate, props.fetch, setError),
  });
  return (
    <form
      className="flex flex-col gap-y-10"
      onSubmit={async (event) => {
        event.preventDefault();
        event.stopPropagation();
        await form.handleSubmit();
      }}
    >
      {globalError && (
        <Alert level="warning">
          {globalError} Contact{" "}
          <a className="font-bold underline" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>{" "}
          for assistance.
        </Alert>
      )}

      <p>
        Add a new resource to your collection. You may upload a media file after the resource
        container has been created.
      </p>
      <form.Field
        name="name"
        validators={{
          onBlur: (value) => (!value.value ? "" : null),
        }}
      >
        {(field) => (
          <Input
            id="name"
            label="Resource Name"
            placeholder="Biblical Doctrine"
            type="text"
            required
            error={field.state.meta.errors.join("\n")}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            shouldFocus={true}
            autoComplete="off"
          />
        )}
      </form.Field>

      <div className="w-fit">
        <form.Subscribe
          selector={(state) => ({
            canSubmit: state.canSubmit,
            submitting: state.isSubmitting,
          })}
        >
          {({ canSubmit, submitting }) => (
            <Button color="green" disabled={!canSubmit}>
              {submitting ? "..." : "Submit"}
            </Button>
          )}
        </form.Subscribe>
      </div>
    </form>
  );
}
