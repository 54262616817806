import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { Effect } from "effect";
import { pdfjs } from "react-pdf";
import { DocumentCallback, OnDocumentLoadSuccess } from "react-pdf/src/shared/types.js";

import { AccountProviderId, ResourceId } from "../schemas";
import { BACKEND_URL, getCookie } from "../utils/fetch";
import { AccessSearch } from "../pages/access/lib";
import { API } from "../api";
import { Button } from "../components/button";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const Route = createFileRoute("/access/$id")({
  component: Component,
  validateSearch: (search: Record<string, unknown>): AccessSearch => {
    if (!("accountProvider" in search)) {
      // TODO: Redirect to error page
      throw new Error("No account");
    }

    return {
      accountProvider: AccountProviderId(search.accountProvider as string),
    };
  },
});

function AuthenticationFlowStarter() {
  const search = Route.useSearch();
  const loc = Route.useMatch();
  const ref = useRef<HTMLFormElement>(null);
  const accountProvider = search.accountProvider;
  const next = `${window.origin}${loc.pathname}?accountProvider=${search.accountProvider}`;

  useEffect(() => {
    if (ref && ref.current) ref.current.submit();
  }, []);

  return (
    <form
      ref={ref}
      action={`${BACKEND_URL}/api/saml2/${accountProvider}/login/?next=${next}`}
      method="POST"
      id="hiddenlogin"
    >
      <input name="csrfmiddlewaretoken" type="hidden" value={getCookie("csrftoken") ?? ""} />
      <input type="submit" />
    </form>
  );
}

function PDFViewer({ url }: { url: string }) {
  const [currentPage, setCurrentPage] = useState<number>(3);
  const [totalPages, setTotalPages] = useState<number>();

  const makeButton = (text: string, page: number, disabled: boolean) => {
    return (
      <div className="w-fit">
        <Button onClick={() => setCurrentPage(page)} disabled={disabled}>
          {text}
        </Button>
      </div>
    );
  };

  const prevButton
    = totalPages && currentPage > 1
      ? makeButton("Previous", currentPage - 1, false)
      : makeButton("Previous", currentPage, true);
  const nextButton
    = totalPages && currentPage < totalPages
      ? makeButton("Next", currentPage + 1, false)
      : makeButton("Next", currentPage, true);

  const loadSuccess: OnDocumentLoadSuccess = (doc: DocumentCallback) => {
    setTotalPages(doc.numPages);
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col">
        <Document className="size-fit" file={url} onLoadSuccess={loadSuccess}>
          <Page className="size-fit" renderTextLayer={false} pageNumber={currentPage} />
        </Document>
        <div className="flex items-center justify-center gap-4">
          {prevButton}
          Page {currentPage} of {totalPages}
          {nextButton}
        </div>
      </div>
    </div>
  );
}

function Resource() {
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const ctx = Route.useRouteContext();
  const params = Route.useParams();

  useEffect(() => {
    async function getResource() {
      const result = await Effect.provide(API.getResource(ResourceId(params.id)), ctx.fetch).pipe(
        Effect.runPromiseExit,
      );

      if (result._tag === "Failure") setError("Unable to get the resource");
      if (result._tag === "Success") {
        if (result.value.url) setUrl(result.value.url);
        else setError("Resource has not media");
      }
    }

    getResource();
  }, [ctx.fetch, params.id]);

  return url ? <PDFViewer url={url} /> : <div>{error}</div>;
}

function Component() {
  const ctx = Route.useRouteContext();

  // TODO: Request the file when the users is logged in
  return ctx.session.isAuthenticated ? <Resource /> : <AuthenticationFlowStarter />;
}
