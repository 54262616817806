import type { FileRouteTypes } from "../routeTree.gen";

/** This is a fun little hack to have a list of all routes availble in the application as an array.
 *
 * If a new route is added to FileRoutesTypes we should get a type error that allRoutesObject is not
 * complete.If you are here because something is missing, simply add the route to the object with a null
 * value.
 *
 * This const isn't exported only the array is because we don't actually want to expose it
 *
 * NOTE: This should staty here and shouldn't be relocated in the app because of circular imports
 */
const allRoutesObject: Record<FileRouteTypes["to"], null> = {
  "": null,
  "/": null,
  "/signin": null,
  "/signup": null,
  "/dashboard": null,
  "/account-start": null,
  "/subscribe": null,
  "/organization-profile": null,
  "/resource/$id": null,
  "/resource": null,
  "/access/$id": null,
};

export const allRoutesTo = Object.keys(allRoutesObject) as FileRouteTypes["to"][];
export type AnyRoute = FileRouteTypes["to"];

export function routeOrDefault(route: unknown): AnyRoute {
  const matchedRoute = allRoutesTo.find((elem) => elem == route);
  return matchedRoute ?? "/dashboard";
}
