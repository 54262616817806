import clsx from "clsx";
import { Record } from "effect";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";

type FinalButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren & {
    color?: Color;
  };

export type Color = "indigo" | "red" | "green";

const backgroundColor: Record<Color, string> = {
  red: "hover:bg-red-500 bg-red-600 focus-visible:outline-red-600 disabled:bg-red-300 disabled:hover:bg-red-200",
  indigo:
    "hover:bg-indigo-500 bg-indigo-600 focus-visible:outline-indigo-600 disabled:bg-indigo-300 disabled:hover:bg-indigo-200",
  green:
    "hover:bg-green-500 bg-green-600 focus-visible:outline-green-600 disabled:bg-green-300 disabled:hover:bg-green-200",
};

const textColor = (color: Color) => {
  switch (color) {
    case "red":
    case "indigo":
    case "green":
      return "text-white";
  }
};

export function Button({ color, ...props }: FinalButtonProps) {
  const finalColor = color ?? "indigo";
  return (
    <button
      {...props}
      className={clsx(
        "flex w-full cursor-pointer justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6",
        "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        backgroundColor[finalColor],
        textColor(finalColor),
        "disabled:cursor-not-allowed",
        props.className,
      )}
    >
      {props.children}
    </button>
  );
}
