/* eslint-disable react/no-children-prop */
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useForm } from "@tanstack/react-form";
import { Effect, Record } from "effect";
import { useState } from "react";

import { Input } from "../../components/input";
import { Card } from "../../components/card";
import { API } from "../../api";
import { Button } from "../../components/button";
import { AnyRoute, routeOrDefault } from "../../utils/router";
import { ErrorDetail } from "../../schemas";

type SigninSearch = {
  next: AnyRoute;
};

export const Route = createFileRoute("/_public/signin")({
  validateSearch: (search: Record<string, unknown>): SigninSearch => {
    return {
      next: routeOrDefault(search.next),
    };
  },
  component: Component,
  beforeLoad: ({ context, search }) => {
    if (context.session.isAuthenticated) {
      return redirect({ to: search.next });
    }
  },
});

function Header() {
  return (
    <div>
      <h1 className="text-center text-xl leading-9">Digital Collections</h1>
      <h2 className="mt-3 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Sign in to your account
      </h2>
    </div>
  );
}

function SigninForm() {
  const [globalError, setGlobalError] = useState<string[]>([]);
  const { setSession, fetch } = Route.useRouteContext();
  const navigate = Route.useNavigate();
  const search = Route.useSearch();

  const form = useForm({
    onSubmit: async ({ value, formApi }) => {
      const updateErrorState = ({ param, message }: ErrorDetail) => {
        // NOTE(NZ): This was simplest way to get the type checking to be happy. I don't
        // love the switch but ¯\_(ツ)_/¯
        switch (param) {
          case "email":
          case "password":
            formApi.setFieldMeta(param, (prev) => ({
              ...prev,
              errorMap: { onBlur: message },
            }));
            break;

          // NOTE(NZ): If someone tries spamming the login page it will hand back a response with no
          // `params` key. We can treat that as a global message
          default:
            setGlobalError(() => {
              return ["The form submission failed. Try again?"];
            });
            break;
        }
      };

      const resp = await Effect.provide(API.signin(value.email, value.password), fetch).pipe(
        Effect.runPromiseExit,
      );

      if (resp._tag === "Success") {
        setSession(resp.value);
        navigate({ to: search.next });
      } else if (resp.cause._tag === "Fail" && resp.cause.error.original?._tag == "APIError") {
        resp.cause.error.original.payload.forEach(updateErrorState);
      } else if (resp.cause._tag === "Fail") {
        setGlobalError([resp.cause.error.displayMessage]);
      } else {
        setGlobalError(["An error occured."]);
      }
    },
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <form
      className="space-y-6"
      onSubmit={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await form.handleSubmit();
      }}
    >
      <div data-testid="fields" className="space-y-6">
        {globalError}
        <form.Field
          name="email"
          validators={{
            onBlur: ({ value }) => {
              if (!value.includes("@")) return "Not a valid email address";
            },
          }}
          children={(field) => (
            <Input
              id="email-address"
              name="email-address"
              type="email"
              label="Email Address"
              placeholder="Email Address"
              autoComplete="email"
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              error={field.state.meta.errors.join(" ")}
              required
            />
          )}
        />
        <form.Field
          name="password"
          validators={{
            onBlur: (value) => {
              if (!value.value) return "You must enter a password";
            },
          }}
        >
          {(field) => (
            <Input
              id="password"
              name="password"
              type="password"
              label="Password"
              placeholder="Password"
              autoComplete="current-password"
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              required
              error={field.state.meta.errors.join(" ")}
            />
          )}
        </form.Field>
      </div>
      <form.Subscribe selector={({ canSubmit, isSubmitting }) => [canSubmit, isSubmitting]}>
        {([canSubmit, isSubmitting]) => (
          <Button
            disabled={!canSubmit}
            title={canSubmit ? "Sign In" : "Correct form errors to sign in"}
          >
            {isSubmitting ? "..." : "Submit"}
          </Button>
        )}
      </form.Subscribe>
    </form>
  );
}

function Component() {
  return (
    <Card className="space-y-6">
      <Header />
      <SigninForm />
    </Card>
  );
}
