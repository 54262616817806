import { Schema as S, Context, Effect, Layer, pipe, ParseResult } from "effect";

import { errorResponseSchema, ResponseErrorMessages } from "../schemas";

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const AUTH_BASE_URL = `${BACKEND_URL}/api/browser/v1`;

type DeclaredErrors =
  | BadJSONError
  | FetchError
  | APIError
  | ParseResult.ParseError
  | BadCodeError
  | WrongContentType;

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export class FetchError {
  readonly original: unknown;
  readonly _tag = "FetchError";

  constructor(err: unknown) {
    this.original = err;
  }
}

export class APIError {
  readonly original: DeclaredErrors | undefined;
  readonly payload: ResponseErrorMessages;
  readonly _tag = "APIError";

  constructor(err: ResponseErrorMessages, original?: DeclaredErrors) {
    this.payload = err;
    this.original = original;
  }

  /**
   * Return a single comma seperated string of all .message members
   */
  combinedMessage(): string {
    return this.payload.reduce((acc, e, index) => {
      return (index === 0 ? "" : ", ") + acc + e.message;
    }, "");
  }
}

export class BadCodeError {
  readonly expected: number;
  readonly response: Response;
  readonly _tag = "BadCodeError";

  constructor(resp: Response, expected: number) {
    this.expected = expected;
    this.response = resp;
  }

  toAPIError(): Effect.Effect<never, APIError, never> {
    if (this.response.status == 409) {
      return Effect.fail(
        new APIError(
          [
            {
              message: "There was a conflict when submitting your request",
              code: "conflict-on-request",
              param: null,
            },
          ],
          this,
        ),
      );
    }

    return pipe(
      this.response,
      expectJson,
      Effect.flatMap(getPayload),
      Effect.flatMap(S.decode(errorResponseSchema)),
      Effect.catchAll((e) =>
        Effect.fail(
          new APIError(
            [
              {
                message: "An error occured but we aren't able to read the response.",
                code: "bad-error-response",
                param: null,
              },
            ],
            e,
          ),
        ),
      ),
      Effect.flatMap((p) => Effect.fail(new APIError(p.errors))),
    );
  }
}

export class BadJSONError {
  readonly _tag = "BadJSONError";
  readonly original: unknown;

  constructor(err: unknown) {
    this.original = err;
  }
}

export class WrongContentType {
  readonly _tag = "WrongContentType";
  readonly expected: string;
  readonly got: string;

  constructor(expected: string, got: string) {
    this.expected = expected;
    this.got = got;
  }
}

/**
 * DisplayableError is the final error handling mechanism that the API should return to components
 * after a fetch is attempted. It should always return a message that can be displayed to the user
 * regardless of cause.
 */
export class DisplayableError {
  readonly original: DeclaredErrors | undefined;
  readonly displayMessage: string;
  readonly _tag = "DisplayableError";

  constructor(displayMessage: string, original?: DeclaredErrors | undefined) {
    this.original = original;
    this.displayMessage = displayMessage;
  }
}

/** Fetch is a context that can be supplied to simplify testing and inject the actual fetching
 * mechanism
 *
 * Use like this:
 *
 *  const program = Fetch.pipe(
 *    Effect.flatMap((f: Fetch) => f.fetch(...)
 *  )
 *
 *  Effect.provideService(program, Fetch, FetchLive)
 */
export class Fetch extends Context.Tag("Fetch")<
  Fetch,
  {
    readonly fetch: typeof request;
  }
>() {}

/**
 * Returns a function that will evaluate a response return code and fail if it doesn't match
 *
 * Usage:
 */
export const requireOk = (resp: Response) =>
  Effect.if(resp.status >= 200 && resp.status < 300, {
    onTrue: () => Effect.succeed(resp),
    onFalse: () => new BadCodeError(resp, 200).toAPIError(),
  });

export const extractData = <D>({ data }: { data: D }): D => data;

/**
 * Try to extract the JSON payload from a request or fail
 */
export const getPayload = (resp: Response) =>
  Effect.tryPromise({ try: () => resp.json(), catch: (err) => new BadJSONError(err) });

export const expectJson = (resp: Response) =>
  Effect.if(resp.headers.get("content-type") === "application/json", {
    onTrue: () => Effect.succeed(resp),
    onFalse: () =>
      Effect.fail(
        resp.status === 404
          ? new BadCodeError(resp, 200)
          : new WrongContentType(resp.headers.get("content-type") || "unknown", "application/json"),
      ),
  });

export const standardErrorMessages = (error: DeclaredErrors): DisplayableError => {
  switch (error._tag) {
    case "ParseError":
    case "BadJSONError":
      return new DisplayableError("Unable to understand the server response. Try again.", error);
    case "FetchError":
      return new DisplayableError("The server might be unreachable. Try again.", error);
    case "APIError":
      return new DisplayableError(error.combinedMessage(), error);
    case "BadCodeError":
      return new DisplayableError("The server responded in an unexected way.", error);
  }
  return new DisplayableError(
    "The data from the server was malformed. Are your search parameters correct?",
    error,
  );
};

export const FetchLive = Layer.succeed(
  Fetch,
  Fetch.of({
    fetch: request,
  }),
);

export type FetchService = typeof FetchLive;

/**
 * Complete a fetch request to the backend
 */
interface RequestParams {
  method: HttpMethod;
  url: string;
  data?: unknown;
  files?: FormData;
  accept?: string;
}

export function request({ method, url, data, files, accept }: RequestParams) {
  const webHost = new URL(document.documentURI).host;
  const apiHost = new URL(url).host;
  const shoudIncludeCredentials = apiHost != webHost;

  const headers = new Headers();

  const options: RequestInit = {
    method,
    // We manually handle all redirects. For example if this returns a 302 which gets handed
    // back to a loader the loader will do the redirect.
    redirect: "manual",
  };

  if (shoudIncludeCredentials) options.credentials = "include";

  const csrfToken = getCookie("csrftoken");

  if (
    csrfToken
    && url !== `${AUTH_BASE_URL}/config`
    && url.startsWith(BACKEND_URL)
    && requiresCSRF(method)
  ) {
    headers.append("x-csrftoken", csrfToken);
  }

  if (data && files) {
    Effect.fail(
      new DisplayableError(
        "An application error occured and this has been reported to staff",
        new FetchError("Programming error. A request may not receive both `data` and `files."),
      ),
    );
  }

  if (accept === undefined) headers.append("Accept", "application/json");
  else headers.append("Accept", accept);

  if (data !== undefined) {
    options.body = JSON.stringify(data);
    headers.append("Content-Type", "application/json");
  }

  if (files !== undefined) {
    options.body = files;
    // headers.append("Content-Type", "multipart/form-data");
  }

  options.headers = headers;
  return Effect.tryPromise({ try: () => fetch(url, options), catch: (err) => new FetchError(err) });
}

/**
 * Return a cookie if it is available from the browser otherwise null
 */
export function getCookie(name: string): string | null {
  let resp: string | null = null;
  if (!document.cookie || document.cookie === "") return resp;

  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.substring(0, name.length + 1) !== name + "=") continue;

    resp = decodeURIComponent(cookie.substring(name.length + 1));
    break;
  }

  return resp;
}

/**
 * Determins if the HTTP Method requires a CSRF token
 */
function requiresCSRF(method: HttpMethod) {
  switch (method) {
    case HttpMethod.GET:
      return false;
    default:
      return true;
  }
}
