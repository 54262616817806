import { createFileRoute, redirect } from "@tanstack/react-router";
import { Effect } from "effect";
import { useState } from "react";
import { ArrowDownTrayIcon, CheckIcon } from "@heroicons/react/24/outline";

import { Card } from "../../components/card";
import { EditableHeading } from "../../components/heading";
import { API } from "../../api";
import { ResourceId, Resource } from "../../schemas";
import { Button } from "../../components/button";
import { deleteResource } from "../../pages/dashboard/lib";
import { updateResourceName, updateResourceFile } from "../../pages/resource/lib";
import { Alert } from "../../components/alert";
import { DisplayableError } from "../../utils/fetch";
import { Input } from "../../components/input";

export const Route = createFileRoute("/_auth/resource/$id")({
  component: () => <Component />,
  loader: async ({ params, context }): Promise<{ resource: Resource }> => {
    const resp = await Effect.provide(API.getResource(ResourceId(params.id)), context.fetch).pipe(
      Effect.runPromiseExit,
    );

    if (resp._tag === "Success") return { resource: resp.value };

    if (
      resp.cause._tag == "Fail"
      && resp.cause.error.original?._tag == "APIError"
      && resp.cause.error.original.original?._tag == "BadCodeError"
      && resp.cause.error.original.original.response.status === 404
    ) {
      throw redirect({
        from: "/resource/$id",
        to: "/dashboard",
        state: {
          showMessage: {
            level: "warning",
            message: `The requested resource does not exist. Requested id: ${params.id} `,
          },
        },
      });
    }

    throw new DisplayableError("Unable to load page");
  },
});

function Component() {
  const ctx = Route.useRouteContext();
  const navigate = Route.useNavigate();
  const { resource } = Route.useLoaderData();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  return (
    <Card>
      {error && <Alert level="error">{error}</Alert>}
      {success && (
        <div className="mb-6">
          <Alert level="success">{success}</Alert>
        </div>
      )}
      <div className="flex flex-col gap-12">
        <EditableHeading
          id="card-heading"
          text={resource.name}
          size="3xl"
          onChange={(s) => {
            const newResource: Resource = {
              id: resource.id,
              name: s,
              url: resource.url,
              filename: resource.filename,
            };
            updateResourceName({ resource: newResource, fetch: ctx.fetch, setError: setError });
          }}
        />

        <form>
          <div className="flex gap-x-4">
            <Input
              label="Resource Media"
              data-title="Upload new media file"
              id="file"
              type="file"
              accept="application/pdf"
              onChange={async (event) =>
                event.currentTarget.files
                && (await updateResourceFile(
                  resource,
                  event.currentTarget.files[0],
                  ctx.fetch,
                  setError,
                ))
                && setSuccess("Uploaded the file")
              }
            />
            {resource.url && (
              <a
                className="mb-2 flex gap-3 self-end text-blue-400"
                target="_blank"
                href={resource.url}
                rel="noreferrer"
              >
                <ArrowDownTrayIcon className="size-6" />
                {resource.filename}
              </a>
            )}
            {success && <CheckIcon className="mb-2 size-6 self-end text-green-500" />}
          </div>
        </form>

        <div className="w-fit">
          <Button
            color="red"
            onClick={async () => {
              await deleteResource({ resourceId: resource.id, fetch: ctx.fetch });
              navigate({ to: "/dashboard" });
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Card>
  );
}
