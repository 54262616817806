import { createFileRoute } from "@tanstack/react-router";
import { Effect } from "effect";

import { Card } from "../../components/card";
import { Heading } from "../../components/heading";
import { Button } from "../../components/button";
import { FetchService } from "../../utils/fetch";
import { API } from "../../api";
import { AccountId, Subscription } from "../../schemas";
import { isSubscriptionActive, subscriptionRenewalLine } from "../../domain";

interface CompomentProps {
  fetch: FetchService;
  accountId: AccountId;
  navigate: ReturnType<typeof Route.useNavigate>;
  subscription: Subscription | undefined;
}

interface LoaderData {
  subscription: Subscription | undefined;
}

export const Route = createFileRoute("/_auth/organization-profile")({
  component: () => <Component />,
  loader: async ({ context: { fetch, selectedAccount } }): Promise<LoaderData> => {
    const resp = await Effect.provide(API.subscription(selectedAccount.id), fetch).pipe(
      Effect.runPromiseExit,
    );

    return resp._tag === "Failure"
      ? {
          subscription: undefined,
        }
      : {
          subscription: resp.value,
        };
  },
});

async function cancelSubscription({ accountId, fetch, navigate }: CompomentProps) {
  await Effect.provide(API.cancelSubscribe(accountId), fetch).pipe(Effect.runPromiseExit);

  navigate({ to: "." });
}

function SubscriptionDetails({
  subscription,
  props,
}: {
  subscription: Subscription;
  props: CompomentProps;
}) {
  return (
    <div className="flex flex-col gap-4">
      <Heading size="lg">Subscription Details</Heading>
      <div className="flex justify-between">
        <p>{subscriptionRenewalLine(subscription)}</p>
        {isSubscriptionActive(subscription) && (
          <div className="w-fit">
            <Button color="red" onClick={() => cancelSubscription({ ...props })}>
              Cancel Subscription
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function Component() {
  const navigate = Route.useNavigate();
  const ctx = Route.useRouteContext();
  const loaderData = Route.useLoaderData();

  const props: CompomentProps = {
    fetch: ctx.fetch,
    accountId: ctx.selectedAccount.id,
    navigate: navigate,
    subscription: loaderData.subscription,
  };

  return (
    <Card>
      <Heading size="3xl" className="mb-6">
        {ctx.selectedAccount.name}
        {props.subscription && (
          <SubscriptionDetails subscription={props.subscription} props={props} />
        )}
      </Heading>
    </Card>
  );
}
